<template>
  <div class="w-100">
    <b-row v-if="loading">
      <b-col cols="12">
        <div class="d-flex justify-content-center m-2">
          <b-spinner variant="primary" style="width: 3rem; height: 3rem" />
        </div>
      </b-col>
    </b-row>
    <vue-apex-charts
      v-else
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    ></vue-apex-charts>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { useToast } from "vue-toastification/composition";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    statement: {
      type: Array,
      required: true,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup() {
    const { skin } = useAppConfig();
    return { toast: useToast(), skin };
  },
  computed: {
    defaultColor() {
      return this.skin === "dark" ? "#b4b7bd" : "";
    },
    categoryColors() {
      return {
        Leads: "#3ba3f3",
        "Road Show": "#c62fc9",
        "Dinâmica de Grupo": "#f32f6d",
        "Entrevista Individual": "#f58240",
        "Pré-Job Sample": "#775dd0",
        "Job Sample": "#546e7a",
        "Aprovação Final": "#26a69a",
        "Convertido em PN": "#D10CE8",
      };
    },
    chartOptions() {
      const colors = this.statement.map(
        (item) => this.categoryColors[item.category] || "#666"
      );

      return {
        chart: {
          type: "bar",
          height: 350,
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 800,
          },
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: false,
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        legend: {
          show: false,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 4,
            barHeight: "70%",
            distributed: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetX: 32,
          style: {
            fontSize: "12px",
            fontWeight: 600,
            colors: ["#fff"],
          },
          formatter: function (val) {
            return val === 0 ? "" : val;
          },
        },
        xaxis: {
          type: "category",
          categories: this.statement.map((item) => item.category),
          labels: {
            style: {
              colors: this.defaultColor,
              fontSize: "12px",
              fontWeight: 600,
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: this.defaultColor,
              fontSize: "12px",
            },
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        colors: colors,
      };
    },
    series() {
      return [
        {
          name: "Quantidade",
          data: this.statement.map((item) => item.value),
        },
      ];
    },
  },
};
</script>
